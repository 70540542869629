import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const AgentProtectedRoute = () => {
    const {isAgentLoggedIn}=useSelector(state=>state.agent)
  return (
   isAgentLoggedIn?<Outlet/>:<Navigate to='/agent_login'/>
  )
}

export default AgentProtectedRoute