import React, { useEffect, useState } from 'react'
import '../styles/agent_setting.css'
import { useDispatch, useSelector } from 'react-redux'

import HorizontalDivider from '../../../../shared/components/HorizontalDivider'
import InputField from '../../../../shared/components/InputField'
import ActionButton from '../../../../shared/components/ActionButton'
import Card from '../../AdminDashBoard/components/Card'
import { restAgentPassword } from '../redux/agentSlice'
import {toast} from 'react-toastify'

const AgentSetting = () => {
  const dispatch=useDispatch()
  const [isValid,setIsValid]=useState(false)
  const {agentBasicInfo}=useSelector(state=>state.agent)
  const [agentPasswordData,setAgentData]=useState({
    oldPassword:'',
    newPassword:'',
    agentId:agentBasicInfo?.agentId
  })


  return (
    <div className='agent-setting-container'>
     <Card 
       width={'100%'}
       element={
       <div className='agent-rest-password-container'>
        <h1 className='rest-title'>Rest password</h1>
        <HorizontalDivider bgColor={'rgba(255,255,255,0.3)'}/>
        <div className="resting-input-field">
          <InputField
            hidden={true}
            borColor={'rgba(255,255,255,0.5)'}
            placeholder={"Old Password"}
            type={'password'}
            setValidation={setIsValid}
            setValue={(e)=>setAgentData({...agentPasswordData,oldPassword:e.target.value})}
            inputValue={agentPasswordData?.oldPassword}
          />
          <InputField
            hidden={true}
            borColor={'rgba(255,255,255,0.5)'}
            placeholder={"New Password"}
            type={'password'}
            setValidation={setIsValid}
            setValue={(e)=>setAgentData({...agentPasswordData,newPassword:e.target.value})}
            inputValue={agentPasswordData?.newPassword}
          />
        </div>
        <div className="rest-btn-container">
          <ActionButton
            label={'Rest Password'}
            width={'200px'}
            handleAction={()=>dispatch(restAgentPassword({agentPasswordData,toast}))}
          />
        </div>
       </div>}
       />
      
    </div>
  )
}

export default AgentSetting