import React, { Suspense, lazy } from 'react'
import {Routes,Route} from 'react-router-dom'
import LoadingPage from '../shared/components/LoadingPage'
import AdminMainOutLet from '../pages/main/AdminDashBoard/pages/AdminMainOutLet'
import AgentProtectedRoute from '../pages/main/agent/route/AgentProtectedRoute'
import AgentMainPage from '../pages/main/agent/pages/AgentMainPage'
import AgentLogin from '../pages/main/agent/pages/AgentLogin'

const Home =lazy(()=>import('../pages/main/pages/HomePage'))
const SignUp =lazy(()=>import('../pages/auth/pages/SignUp'))
const Login =lazy(()=>import('../pages/auth/pages/Login'))
const UserProfilePage =lazy(()=>import('../pages/profile_pages/UserProfilePage'))
const AboutUs =lazy(()=>import('../pages/main/pages/AboutUs'))
const Collections =lazy(()=>import('../pages/main/pages/Collections'))
const ContactUs =lazy(()=>import('../pages/main/pages/ContactUs'))
const News =lazy(()=>import('../pages/main/pages/News'))
const CartHomePage =lazy(()=>import('../pages/main/pages/CartHomePage'))
const PaymentSystem =lazy(()=>import('../pages/main/pages/PaymentSystem'))
const PrivacyPolicy =lazy(()=>import('../pages/auth/pages/PrivacyPolicy'))
const ForgotPassword =lazy(()=>import('../pages/auth/pages/ForgotPassword'))
const SendToEmail =lazy(()=>import('../pages/auth/pages/SendToEmail'))
const SellerMainPage =lazy(()=>import('../pages/sellingPage/pages/SellerMainPage'))
const  AdminMainPage=lazy(()=>import('../pages/main/AdminDashBoard/pages/AdminMainPage'))
const  AdminLogin=lazy(()=>import('../pages/main/AdminDashBoard/pages/AdminLogin'))
const  PrivateRoute=lazy(()=>import('./PrivateRoute'))



export const MainRoute = () => {
  return (
    <Suspense fallback={<LoadingPage/>}>
    <Routes>
      <Route path='' element={<Home/>}/>
      <Route path='/sign_up' element={<SignUp/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/user_profile_page' element={<UserProfilePage/>}/>
      <Route path='/about' element={<AboutUs/>}/>
      <Route path='/collection' element={<Collections/>}/>
      <Route path='/contact' element={<ContactUs/>}/>
      <Route path='/news' element={<News/>}/>
      <Route path='/cart' element={<CartHomePage/>}/>
      <Route path='/policy' element={<PrivacyPolicy/>}/>
      <Route path='/rest' element={<ForgotPassword/>}/>
      <Route path='/send_mail' element={<SendToEmail/>}/>
      <Route element={<PrivateRoute/>}>
           <Route path='/sell' element={<SellerMainPage/>}/>
            <Route path='/payment' element={<PaymentSystem/>}/>
      </Route>
      <Route path='admin_login' element={<AdminLogin/>}/> 
      <Route element={<AdminMainOutLet isAdminLoggedIn={true}/>}>
         <Route path='/admin' element={<AdminMainPage/>}/>
      </Route>
      <Route path='agent_login' element={<AgentLogin/>}/> 
      <Route element={<AgentProtectedRoute isAgentLoggedIn={true}/>}>
       <Route path='/agent_admin' element={<AgentMainPage/>}/>
    </Route>
    </Routes>
    </Suspense>
  )
}
