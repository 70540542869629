import React from 'react'
import './styles/notification_card.css'
import HorizontalDivider from '../../../../shared/components/HorizontalDivider'
import ActionButton from '../../../../shared/components/ActionButton'
import { FaTrash } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { addTestimonial } from '../../AdminDashBoard/redux/adminSlice'

const NotificationCard = (props) => {
  const dispatch=useDispatch()

  const testimonialData={
    userName:props?.userName,
    userEmail:props?.userEmail,
    userMessage:props?.userMessage
  }
  

  return (
    <div className='notification-card-container'>
        <div className="notification-top-container">
           <span>From Admin</span>   
              {/* <FaTrash className='delete-notification-icon'/>    */}
         </div>
         <HorizontalDivider bgColor={'rgba(255,255,255,0.6)'}/>
        <p className='notification-message-container'>{props?.message}</p>
        <div className="add-delete">
          <span className='date'>{props?.createdAt?.toDateString()}</span>
          <ActionButton
             width={'170px'}
            label={'Reply'}
            handleAction={()=>dispatch(addTestimonial({testimonialData}))}
          />
        </div>
    </div>
  )
}

export default NotificationCard