import React, { useMemo, useState } from 'react'
import '../styles/account.css'
import InputField from '../../../../shared/components/InputField'
import UploadImage from '../../../sellingPage/components/UploadImage'
import Avatar from '../../../../shared/components/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import avatarImage from '../../../../assets/images/user.gif'
import { setUploadType, updateAgentProfileImage, updateAgentPublicId } from '../redux/agentSlice'


const Account = () => {
    const {publicId,profileImage,uploadType} =useSelector(state=>state.agent)
    const {agentBasicInfo} =useSelector(state=>state.agent)
    const [isValid,setValid]=useState(false)
    const dispatch=useDispatch()

   useMemo(()=>{
    const agentPublicIdData={
      agentId:agentBasicInfo.agentId,
      publicId:publicId[0]?.data_url
    }

    const agentProfileImageData={
      agentId:agentBasicInfo.agentId,
      profileImage:profileImage[0]?.data_url
    }

    if(uploadType==='publicId'){
          dispatch(updateAgentPublicId({agentPublicIdData}))
          dispatch(setUploadType(''))
    }
    if(uploadType==='profileImage'){
          dispatch(updateAgentProfileImage({agentProfileImageData}))
          dispatch(setUploadType(''))
    }
   },[uploadType])

  

   console.log(agentBasicInfo);

  return (
    <div className='account-main-container'>
        <div className="agent-profile-image">
            <Avatar 
             size={80} 
             isRound={true}
             image={agentBasicInfo?.profileImage? agentBasicInfo?.profileImage:avatarImage}
             />
            <UploadImage 
              label={'Upload Profile'}
              type={'profileImage'} 
              image={profileImage}/>
        </div>
       <div className="agent-profile-image">
            <Avatar 
             size={80} 
             image={agentBasicInfo.publicId?agentBasicInfo.publicId:avatarImage}
             />
             <UploadImage 
                label={'Upload PublicId'}
               type={'publicId'} 
               image={publicId}
               />
        </div>
       <InputField 
         hidden={true} 
         width={'100%'}
         inputLabel={'FullName'}
         borColor={'rgba(255,255,255,0.5)'}
         inputValue={agentBasicInfo?.fullName}
         setValidation={setValid}
         /> 
         <InputField 
          hidden={true} 
          width={'100%'}
          type={'text'}
          inputLabel={'Agent ID'}
          borColor={'rgba(255,255,255,0.5)'}
           inputValue={agentBasicInfo?.agentId}
         setValidation={setValid}
         /> 
         <InputField 
         hidden={true} 
         width={'100%'}
         inputLabel={'PhoneNumber'}
         borColor={'rgba(255,255,255,0.5)'}
          inputValue={agentBasicInfo?.phoneNumber}
         setValidation={setValid}
         /> 
         <InputField 
          hidden={true} 
          width={'100%'}
          type={'email'}
          inputLabel={'Address'}
          borColor={'rgba(255,255,255,0.5)'}
          inputValue={agentBasicInfo?.address}
         setValidation={setValid}
         />  
         <InputField 
         hidden={true} 
         width={'100%'}
         inputLabel={'password'}
         type={'password'}
         borColor={'rgba(255,255,255,0.5)'}
         inputValue={agentBasicInfo?.password}
         setValidation={setValid}

         /> 
         <InputField 
          hidden={true} 
          width={'100%'}
          type={'email'}
          inputLabel={'Email'}
          borColor={'rgba(255,255,255,0.5)'}
         />  
         
           
    </div>
  )
}

export default Account