import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const AdminMainOutLet = () => {
  const {isAdminLoggedIn}=useSelector(state=>state.admin)
  return (
    true?<Outlet/>:<Navigate to={'/admin_login'}/>
  )
}

export default AdminMainOutLet