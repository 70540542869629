import React, { useEffect } from 'react'
import '../styles/input_field.css'
import { useState } from 'react';
import { 
  validateEmail, 
  validateName, 
  validatePassword, 
  validatePhoneNumber, 
  validateText } from '../utils/validate';
import {FaEye, FaEyeSlash} from 'react-icons/fa'

const InputField = ({
  inputLabel,type,setValue,inputValue,width,setValidation,hidden,placeholder,
  borColor,
  textColor
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isValid,setValid] = useState(false)
  const [isEmpty,setEmpty] = useState()
  const handleClickShowPassword = () => setShowPassword(show =>!show);
  
const validate = (inputValue,type)=>{
    if(type==='name'){ 
      if(validateName(inputValue)!==''){
         setEmpty(validateName(inputValue))
         setValid(false)    
     }else {
      setValid(true) 
    }     
  }else if(type ==='password'){ 
      if(validatePassword(inputValue)!==''){
         setEmpty(validatePassword(inputValue))
         setValid(false)    
     }else setValid(true)
  }else if(type ==='phoneNumber'){ 
      if(validatePhoneNumber(inputValue)!==''){
         setEmpty(validatePhoneNumber(inputValue))
         setValid(false)      
     }else setValid(true)
  }else if(type ==='email'){ 
      if(validateEmail(inputValue)!==''){
         setEmpty(validateEmail(inputValue))
         setValid(false)      
     }else  setValid(true)
  }
    else if(type ==='text'){ 
      if(validateText(inputValue)!==''){
         setEmpty(validateText(inputValue))
         setValid(false)      
     }else  setValid(true)
  }
 
 
}

const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 

useEffect(()=>{
   if(inputValue){
     setValidation(isValid)
     console.log(isEmpty);
   }
},[inputValue,isValid])


const handleInputValueChange=(e)=>{
  setValue(e)
  validate(e.target.value,type)
 
}

  return (
    <div className='input-field-container' style={{width:width}}>
        <label
         style={{color:hidden?textColor?textColor:'[#1e1e1e]':'[#1e1e1e]'}} 
         className='mb-1 text-[14px]'>{inputLabel}</label>
        <div style={{border:`solid 1px ${borColor?borColor:'rgba(0,0,0,0.5)'}`}} 
         className="input-field-inner-container">
        <input 
         style={{
          backgroundColor:hidden?'rgba(0,0,0,0)':'white',
        }}
        type={type==='password'?showPassword?'text':'password':type}
        onChange={handleInputValueChange}
        value={inputValue} 
        placeholder={placeholder} 
        />
         {type==='password'?!showPassword?
         <FaEye
         style={{color:borColor?borColor:'#1e1e1e'}} 
          onClick={handleClickShowPassword} 
          className='icons'/> : 
         <FaEyeSlash onClick={handleClickShowPassword} className='icons'/>:''}
        </div>
        {!isValid?<label className='error'>{isEmpty}</label>:''}
    </div>
  )
}

export default InputField