import React, { useState } from 'react'
import '../styles/agent_login.css'
import InputField from '../../../../shared/components/InputField'
import ActionButton from '../../../../shared/components/ActionButton'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import HorizontalDivider from '../../../../shared/components/HorizontalDivider'
import Avatar from '../../../../shared/components/Avatar'
import agentImage from '../../../../assets/images/agent.png'
import { agentLogin } from '../redux/agentSlice'
import { toast } from 'react-toastify'
const AgentLogin = () => {
    const [agentData,setUserData]=useState({phoneNumber:'',password:''})
    const [isValid,setIsValid]=useState(false)
    const navigate=useNavigate()
    const dispatch=useDispatch()

    const handleForm=()=>dispatch(agentLogin({agentData,navigate,toast}))
    

  return (
    <div className='agent-login-container'>
        <div className="agent-login-inner-card">
            <Avatar
               image={agentImage}
               size={50}            
            />
            <span className='agent-login-title'>Agent Login</span>
            <HorizontalDivider/>
            <div className="input-fields-container">
            <InputField 
            placeholder={'phoneNumber'}
            type={'phoneNumber'}
            setValue={(e)=>setUserData({...agentData,phoneNumber:e.target.value})}
            inputValue={agentData?.phoneNumber}
            setValidation={setIsValid}
             width={'100%'}
             hidden={true}
            />
            <InputField 
            placeholder={'Password'}
            type={'password'}
            setValue={(e)=>setUserData({...agentData,password:e.target.value})}
            inputValue={agentData?.password}
            setValidation={setIsValid}
            width={'100%'}
            hidden={true}
            />
            </div>
        <div  className='agent-forgot-password'>Forgot password</div>
          <ActionButton
           isOutLined={false}
           label={'Login'}
           width={'80%'}
           handleAction={handleForm}
          />
        </div>
    </div>
  )
}

export default AgentLogin