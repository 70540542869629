import React from 'react'
import './style/sidebar_btn.css'

const SideBarBtn = ({icon,label,setSideBarTitle,sidebarTitle}) => {

  return (
    <button onClick={()=>setSideBarTitle(label)} 
      className={`${label===sidebarTitle?'sidebar-btn-container-clicked':'sidebar-btn-container'}`}>
     {icon}
     <span>{label}</span>
    </button>
  )
}

export default SideBarBtn