import React from 'react'
import './style/sidebar.css'
import SideBarBtn from './SideBarBtn'
import { FaArrowLeft} from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { setAdminSideBarVisible } from '../redux/adminSlice'


const SideBar = ({setSideBarTitle,sidebarTitle,data,moreElement,image}) => {
 const dispatch=useDispatch()
  return (
    <div className={`admin-side-bar-main-container`}>
        <div className="logo">
          <img src={image} alt="" className='logo-image' />
          <FaArrowLeft 
            className='cursor-pointer' 
            onClick={()=>dispatch(setAdminSideBarVisible())} 
          />
        </div>
       {data?.map((data,index)=>
         <SideBarBtn 
           key={index}
           icon={data.icon}
           label={data.title}
           setSideBarTitle={setSideBarTitle}
           sidebarTitle={sidebarTitle}
           />
       )
        }
   
        {moreElement}
     
         
    </div>
  )
}

export default SideBar