import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { basicUrl } from "../../utils/api";



axios.interceptors.request.use((req)=>{
    if(localStorage.getItem("userProfile")){
        req.headers.authorization = `Bearer ${(JSON.parse(localStorage.getItem("userProfile")).token)}`
    }
  return req;
})

export const uploadFile = createAsyncThunk('seller/upload',async({uploadData})=>{
        try {
              const response = await axios.post(`${basicUrl}/upload`,uploadData)
                toast.success('file successfully uploaded')
              return response.data 
        } catch (error) {
            toast.error(error.response.data.error)
             
        }
})

const sellerSlice=createSlice({
    name:'seller',
    initialState:{
       image:[],
       isFileUploaded:false
    },
    reducers:{
        setSellerImage:(state,action)=>{
            state.image=action.payload
        },
         removeImage:(state,action)=>{
          state.image=[]
        }
    },
    extraReducers:{
         [uploadFile.pending]:(state,action)=>{
            state.isFileUploaded=false
        },

        [uploadFile.fulfilled]:(state,action)=>{
            state.isFileUploaded=false
        },

        [uploadFile.rejected]:(state,action)=>{
            state.isFileUploaded=false
            
        },
    }
})

export const {setSellerImage,removeImage} = sellerSlice.actions 
export const sellerReducer= sellerSlice.reducer