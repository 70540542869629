import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { basicUrl } from "../../../../utils/api";

export const createNewReports=createAsyncThunk('report/createNewReports',async({reportData})=>{
    try {
          const report =await axios.post(`${basicUrl}/new_report`,reportData)
          return report.data
    } catch (error) {
      console.log(error);        
    }
})


const reportSlice=createSlice({
    name:'report',
    initialState:{
        isLading:false
    },
    extraReducers:{
        [createNewReports.pending]:(state,action)=>{
            state.isLading=true
        },
        [createNewReports.fulfilled]:(state,action)=>{
            state.isLading=false
        },
        [createNewReports.rejected]:(state,action)=>{
            state.isLading=true
        }
    }
})

export const reportReducer=reportSlice.reducer