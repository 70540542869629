import React from 'react'
import DashBoard from './DashBoard'
import Account from './Account'
import Report from './Report'
import Notification from './Notification'
import Wallet from './Wallet'
import AgentSetting from './AgentSetting'

const AgentMainRoute = ({label}) => {
   return (
    <>
     {label==='Dashboard'?<DashBoard/>:
      label==='Account'?<Account/>:
      label==='Report'?<Report/>:
      label==='Notification'?<Notification/>:
      label==='Wallet'?<Wallet/>:
      label==='Setting'?<AgentSetting/>:''
     }
    </>
  )
}

export default AgentMainRoute