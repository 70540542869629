import React from 'react'
import '../styles/admin_nav.css'
import { FaBars } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { setAdminSideBarVisible } from '../redux/adminSlice'

const NavBar = ({title,handleAction}) => {
  const dispatch=useDispatch()
  return (
    <header className='admin-nav-main-container'>
      <div className='label'>
        <FaBars onClick={()=>dispatch(setAdminSideBarVisible())}  className='admin-nav-bar-icon'/>
       <span>{title}</span> 
      </div>
      <span 
       onClick={handleAction} 
       className='logout'>Logout</span>
    </header>
  )
}

export default NavBar