import React, { useEffect, useMemo, useState } from 'react'
import '../styles/report.css'
import Card from '../../AdminDashBoard/components/Card'
import HorizontalDivider from '../../../../shared/components/HorizontalDivider'
import InputField from '../../../../shared/components/InputField'
import ActionButton from '../../../../shared/components/ActionButton'
import UploadImage from '../../../sellingPage/components/UploadImage'
import Avatar from '../../../../shared/components/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { createNewReports } from '../redux/reportSlice'
import gemstoneImage from '../../../../assets/images/gemstone.svg'
import { setModalVisibility } from '../../AdminDashBoard/redux/adminSlice'
// import GemStoneRecite from '../../../documents/pages/GemStoneRecite'
// import { PDFDownloadLink } from '@react-pdf/renderer'

const Report = () => {
  const {image}=useSelector(state=>state.seller)
  const {agentBasicInfo}=useSelector(state=>state.agent)

  const [reportData,setReportData]=useState({
    uploadedBy:'Agent'
  })

  const [isValid,setValid]=useState(false)

  const dispatch=useDispatch()
  useMemo(()=>{
     setReportData({...reportData,image:image})
  },[image])

  const handleForm=()=>{
   dispatch(createNewReports({reportData}))
  }

  useEffect(()=>{
    if(!agentBasicInfo?.status){
      alert('hello')
      dispatch(setModalVisibility())
    }
  },[agentBasicInfo.status])

  console.log(agentBasicInfo);

  return (
    <div className='report-main-container'>
    
      <Card 
       width={'95%'}
       element={
       <div className='seller-card-element-container'>
         <h1 className='px-[10px] py-[5px] mt-3 font-bold'>Seller Information</h1>
          <Avatar image={gemstoneImage} size={40}/>
         <HorizontalDivider bgColor={'rgba(255,255,255,0.4)'}/>
         <div className="seller-basic-info">
         <InputField 
           hidden={true} 
           width={'100%'}
           type={'text'}
           placeholder={'FullName'}
           borColor={'rgba(255,255,255,0.5)'}
           setValidation={setValid}
           setValue={(e)=>setReportData({...reportData,sellerName:e.target.value})}
           inputValue={reportData?.sellerName}
         /> 
         {/* <InputField 
           hidden={true} 
           width={'100%'}
           type={'email'}
           placeholder={'asaeed526@gmail.com'}
           borColor={'rgba(255,255,255,0.5)'}
         />  */}
         <InputField 
          hidden={true} 
          width={'100%'}
          type={'phoneNumber'}
          placeholder={'PhoneNumber'}
          borColor={'rgba(255,255,255,0.5)'}
          setValidation={setValid}
          setValue={(e)=>setReportData({...reportData,sellerPhoneNumber:e.target.value})}
          inputValue={reportData?.sellerPhoneNumber}
         />
         </div>
       </div>}
       />

    <Card 
       width={'95%'}
       height={'auto'}
       element={
       <div className='gemstone-card-element-container'>
         <h1 className='px-[10px] py-[5px] mt-3 font-bold'>Gemstone Information</h1>
         <HorizontalDivider bgColor={'rgba(255,255,255,0.4)'}/>
         <div className="gemstone-basic-info">
         <InputField 
           hidden={true} 
           width={'100%'}
           type={'text'}
           placeholder={'Gemstone Name'}
           borColor={'rgba(255,255,255,0.5)'}
           setValidation={setValid}
           setValue={(e)=>setReportData({...reportData,mineralName:e.target.value})}
           inputValue={reportData?.mineralName}
         /> 
         <InputField 
           hidden={true} 
           width={'100%'}
           type={'text'}
           placeholder={'Weight'}
           borColor={'rgba(255,255,255,0.5)'}
           setValidation={setValid}
           setValue={(e)=>setReportData({...reportData,weight:e.target.value})}
           inputValue={reportData?.weight}
         /> 
         <InputField 
          hidden={true} 
          width={'100%'}
          placeholder={'Price'}
          type={'text'}
          borColor={'rgba(255,255,255,0.5)'}
          setValidation={setValid}
          setValue={(e)=>setReportData({...reportData,price:e.target.value})}
          inputValue={reportData?.price}
         />
         <InputField 
           hidden={true} 
           width={'100%'}
           type={'text'}
           placeholder={'How many Gemstones'}
           borColor={'rgba(255,255,255,0.5)'}
           setValidation={setValid}
           setValue={(e)=>setReportData({...reportData,numberOfGemstone:e.target.value})}
           inputValue={reportData?.numberOfGemstone}
         /> 
         <InputField 
          hidden={true} 
          width={'100%'}
          type={'text'}
          placeholder={'Quality'}
          borColor={'rgba(255,255,255,0.5)'}
           setValidation={setValid}
           setValue={(e)=>setReportData({...reportData,quality:e.target.value})}
           inputValue={reportData?.quality}
         />
         <InputField 
           hidden={true} 
           width={'100%'}
           type={'text'}
           placeholder={'type Polished/Raw'}
           borColor={'rgba(255,255,255,0.5)'}
           setValidation={setValid}
           setValue={(e)=>setReportData({...reportData,type:e.target.value})}
           inputValue={reportData?.type}
         /> 
         <div className="uploaded-image-container">
          <UploadImage/>
          {image[0]?.data_url?
          <Avatar 
            size={200} 
            image={image[0]?.data_url}
           />:''}
         </div>
       
         </div>
       </div>}
       />
     <div className="process-payment-container">
        <ActionButton 
          label={'Process Payment'} 
          width={'200px'}
          handleAction={handleForm}
          />
      {/* <PDFDownloadLink document={<GemStoneRecite/>} fileName={`recite.pdf`}>
      {({ blob, url, loading, error }) =>
         
         <ActionButton
            width={'100%'}
            label={'Generate Pdf'}
            
          />
      }
    </PDFDownloadLink>   */}
     </div>
    </div>
  )
}

export default Report