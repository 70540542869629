import React, { useEffect } from 'react'
import '../styles/wallet.css'
import Card from '../../AdminDashBoard/components/Card'
import HorizontalDivider from '../../../../shared/components/HorizontalDivider'
import { useDispatch, useSelector } from 'react-redux'
import { getWalletInfo } from '../redux/agentSlice'

const Wallet = () => {
  const {walletInfo,agentBasicInfo}=useSelector(state=>state.agent)
  
  const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(getWalletInfo({agentId:agentBasicInfo?.agentId}))
  },[])

  return (
    <div className='wallet-main-container'>
     <Card
       width={'90%'}
       element={
       <div className='wallet-card-element-container'>
         <span className='wallet-title'>Wallet Balance</span>
         <HorizontalDivider bgColor={'rgba(255,255,255,0.4)'}/>
          <div className="balance-info">
            <span>{`Your Balance: ${walletInfo?.walletBalance} EBR`}</span>
            <span>{`Payment Minimum Balance: ${walletInfo?.checkOutBalance} EBR`}</span>
          </div>
       </div>}
     />
    </div>
  )
}

export default Wallet