import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { basicUrl } from "../../../../utils/api";

export const agentLogin=createAsyncThunk('agent/agentLogin',async({agentData,navigate,toast})=>{
    try {
          const response =await axios.post(`${basicUrl}/agent_login`,agentData)
          navigate('/agent_admin')
          toast.success('Welcome back')
          return response.data
    } catch (error) {
      toast.error(error.response.data.message);        
    }
})


export const signUp=createAsyncThunk('agent/signUp',async({agentData,toast})=>{
    try {
          const report =await axios.post(`${basicUrl}/create_new_agent`,agentData)
          toast.success('Agent account successfully created');  
          return report.data
    } catch (error) {
      toast.error(error.response.data.message);        
    }
})


export const getAgentBasicInfo=createAsyncThunk('agent/getAgentBasicInfo',async({agentId})=>{
    try {
          const response =await axios.get(`${basicUrl}/get_agent_info/${agentId}`)
          return response.data
    } catch (error) {
      console.log(error);        
    }
})

export const getAgentMessage=createAsyncThunk('agent/getAgentMessage',async({agentId})=>{
    try {
          const response =await axios.get(`${basicUrl}/get_agent_all_notifications/${agentId}`)
          return response.data
    } catch (error) {
      console.log(error);        
    }
})


export const restAgentPassword=createAsyncThunk('agent/restAgentPassword',async({agentPasswordData,toast})=>{
    try {
          const response =await axios.put(`${basicUrl}/update_password`,agentPasswordData)
          toast.success('Your password has been updated')
          return response.data
    } catch (error) {
      toast.error(error.response.data.message);       
    }
})

export const getWalletInfo=createAsyncThunk('agent/getWalletInfo',async({agentId})=>{
    try {
          const response =await axios.get(`${basicUrl}/get_agent_wallet/${agentId}`)
          return response.data
    } catch (error) {
      console.log(error.response.data.message);       
    }
})


export const updateAgentPublicId=createAsyncThunk('agent/updateAgentPublicId',async({agentPublicIdData,toast})=>{
    try {
          const response =await axios.put(`${basicUrl}/update_agent_public_id`,agentPublicIdData)
          toast.success('Your public id has been updated')
          return response.data
    } catch (error) {
      toast.error(error.response.data.message);       
    }
})


export const updateAgentProfileImage=createAsyncThunk('agent/updateAgentProfileImage',
 async({agentProfileImageData,toast})=>{
    try {
          const response =await axios.put(`${basicUrl}/update_agent_profile_image`,agentProfileImageData)
          toast.success('Your profile image has been updated')
          return response.data
    } catch (error) {
      toast.error(error.response.data.message);       
    }
})



const agentSlice=createSlice({
    name:'agent',
    initialState:{
        isLading:false,
        isAgentLoggedIn:false,
        agentBasicInfo:{},
        agentMessage:[],
        walletInfo:{},
        profileImage:[],
        publicId:[],
        uploadType:''
    },
    reducers:{
        logOut:(state,action)=>{
            state.isAgentLoggedIn=false
            state.agentBasicInfo={}
        },
        setPublicId:(state,action)=>{
          state.publicId=action.payload
        },
        setProfileImage:(state,action)=>{
          state.profileImage=action.payload
        },
        setUploadType:(state,action)=>{
            state.uploadType=action.payload
        },
         removeImage:(state,action)=>{
          if(state.uploadType==='profileImage'){
                state.profileImage=[]
            }else state.publicId=[]
        }
    },
    extraReducers:{
        [agentLogin.pending]:(state,action)=>{
            state.isLading=true
            state.isAgentLoggedIn=false
        },
        [agentLogin.fulfilled]:(state,action)=>{
            state.isLading=false
            state.isAgentLoggedIn=true
            state.agentBasicInfo=action.payload?.userData
           
        },
        [agentLogin.rejected]:(state,action)=>{
            state.isLading=true
            state.isAgentLoggedIn=false
        },

        [getAgentBasicInfo.pending]:(state,action)=>{
            state.isLading=true
        },
        [getAgentBasicInfo.fulfilled]:(state,action)=>{
            state.isLading=false
            state.agentBasicInfo=action.payload
        },
        [getAgentBasicInfo.rejected]:(state,action)=>{
            state.isLading=true
        },
        [getAgentMessage.pending]:(state,action)=>{
            state.isLading=true
        },
        [getAgentMessage.fulfilled]:(state,action)=>{
            state.isLading=false
            state.agentMessage=action.payload
        },
        [getAgentMessage.rejected]:(state,action)=>{
            state.isLading=true
        },
        [getWalletInfo.pending]:(state,action)=>{
            state.isLading=true
        },
        [getWalletInfo.fulfilled]:(state,action)=>{
            state.isLading=false
            state.walletInfo=action.payload
        },
        [getWalletInfo.rejected]:(state,action)=>{
            state.isLading=true
        }
    }
})
export const {logOut,setPublicId,setProfileImage,setUploadType,removeImage}=agentSlice.actions
export const agentReducer=agentSlice.reducer