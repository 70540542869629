import React from 'react'

const HorizontalDivider = ({bgColor}) => {
  return (
    <div style={{
      width:'100%',
      height:'1px',
      backgroundColor:bgColor?bgColor:'rgba(0,0,0,0.2)'}}/>
  )
}

export default HorizontalDivider