import React, { useState } from 'react'
import '../styles/agent_main_page.css'
import { useDispatch, useSelector } from 'react-redux'
import SideBar from '../../AdminDashBoard/components/SideBar'
import NavBar from '../../AdminDashBoard/components/NavBar'
import { sideBar_list } from '../utils/agent_side_bar'
import AgentMainRoute from './AgentMainRoute'
import sideBarImage from '../../../../assets/images/agent.png'
import { logOut } from '../redux/agentSlice'

const AgentMainPage = () => {
   const [sidebarTitle,setSideBarTitle]=useState('Dashboard')
   const {isAdminSideBarVisible}=useSelector(state=>state.admin)
   const dispatch=useDispatch()


  return (
    <div className='agent-main-page-container'>
       {isAdminSideBarVisible?
       <div className="agent-left-container">
        <SideBar 
          image={sideBarImage}
          data={sideBar_list}
          sidebarTitle={sidebarTitle} 
          setSideBarTitle={setSideBarTitle}
          moreElement={<div className='w-full h-200px'></div>}
        />
        </div>:null}
        <div className="agent-right-container">
            <NavBar 
              handleAction={()=>dispatch(logOut())} 
              title={sidebarTitle}
            />
            <AgentMainRoute label={sidebarTitle}/>
        </div>
    </div>
  )
}

export default AgentMainPage