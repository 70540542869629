import React, { useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css";
import '../styles/table.css'

const Table = ({data,tableFormat}) => {
   
  

  return (
    <div className='table-container'>
      <AgGridReact 
        style={{ width: '100%', height: '100%',backgroundColor:'#0b1d2896' }} 
        rowData={data} 
        columnDefs={tableFormat}
      />
  </div>
  )
}

export default Table