import React from 'react'
import '../styles/dashboard.css'
import Card from '../../AdminDashBoard/components/Card'
import svgImage from '../../../../assets/images/gemstone.svg'
import Avatar from '../../../../shared/components/Avatar'
import { FaWallet } from 'react-icons/fa'
import { FaMessage } from 'react-icons/fa6'
const DashBoard = () => {
  return (
    <div className='agent-dashboard-container'>
       <div className="dashboard-top-container">
          <Card
            height={'150px'}
            width={'100%'}
            element={
            <div className='card-element-container'>
              <Avatar size={80} image={svgImage}/>
               <span>200+</span> 
            </div>}
          />
          <Card
            height={'150px'}
            width={'100%'}
            element={
            <div className='card-element-container'>
              <FaMessage className='text-[40px] text-orange-400'/>
               <span>Notification</span> 
            </div>}
          />
          <Card
            height={'150px'}
            width={'100%'}
            element={
            <div className='card-element-container'>
              <FaWallet className='text-[40px] text-orange-400'/>
                <span>Wallet</span> 
            </div>}
          />
        </div>
    </div>
  )
}

export default DashBoard