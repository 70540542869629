import { configureStore } from "@reduxjs/toolkit";

import {persistReducer} from 'redux-persist'
import {combineReducers} from '@reduxjs/toolkit'
import storage from "redux-persist/lib/storage"
import { cartReducer } from "./features/cartSlice";
import { userReducer } from "./features/userSlice";
import { sellerReducer } from "./features/sellerSlice";
import { generalReducer } from "./features/generalSlice";
import { reportReducer } from "../pages/main/agent/redux/reportSlice";
import { adminReducer } from "../pages/main/AdminDashBoard/redux/adminSlice";
import { agentReducer } from "../pages/main/agent/redux/agentSlice";


const persistConfig = {
  key: 'persistCollection',
  storage,
}
  
const reducer = combineReducers({
 cart:cartReducer,
 user:userReducer,
 seller:sellerReducer,
 general:generalReducer,
 admin:adminReducer,
 report:reportReducer,
 agent:agentReducer
})

const persistedReducer = persistReducer(persistConfig,reducer);
export const store = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    
})




