import { createSlice } from "@reduxjs/toolkit";


const generalSlice=createSlice({
    name:'general',
    initialState:{
        navName:'Home'
    },
    reducers:{ 
    setNavigation:(state,action)=>{
        state.navName=action.payload
        console.log(action.payload);
    }
}
})

export const {setNavigation}=generalSlice.actions
export const  generalReducer=generalSlice.reducer