import React from 'react'
import '../styles/action_btn.css'

const ActionButton = ({label,handleAction,isOutLined,width,color}) => {
  return (
    <div 
     onClick={handleAction} 
     style={{width:width,color:isOutLined?`${color?color:'#1e1e1e'}`:'#1e1e1e'}} 
     className={isOutLined?'action-btn-container-out-lined':'action-btn-container-filled'}>
      {label}
    </div>
  )
}

export default ActionButton