import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios'
import {toast} from 'react-toastify'
import { basicUrl } from "../../utils/api";



axios.interceptors.request.use((req)=>{
    if(localStorage.getItem("userProfile")){
        req.headers.authorization = `Bearer ${(JSON.parse(localStorage.getItem("userProfile")).token)}`
    }
  return req;
})

export const newBuyer = createAsyncThunk('user/newBuyer',async({userData,navigate})=>{
      
        try {
              const response = await axios.post(`${basicUrl}/signup`,userData)
               toast.success('SuccessFully account created')
               navigate('/home')
              return response.data 
             
        } catch (error) {
           toast.error(error.response.data.error)
           console.log(error)
           
        }
})

export const logIn = createAsyncThunk('user/logIn',async({userData,navigate})=>{
    console.log(userData);
        try {
              const response = await axios.post(`${basicUrl}/signIn`,userData)
                toast.success('login is successFull')
                navigate('/')
              return response.data 
        } catch (error) {
            toast.error(error.response.data.error)
             
        }
})


export const getUser = createAsyncThunk('user/getUser',async()=>{
        
        try {
              const response = await axios.get(basicUrl)
              console.log('hello',response.data)
              return response.data 
        } catch (error) {
            console.log(error)
        }
})

export const sendFeedback = createAsyncThunk('user/sendFeedBack',async({feedbackData,toast})=>{
        
        try {
              const response = await axios.post(`${basicUrl}/newFeedback`,feedbackData)
              console.log(response.data)
              return response.data 
        } catch (error) {
            console.log(error)
        }
})

export const getAllTestimonials = createAsyncThunk('user/getAllTestimonials',async()=>{
        
        try {
              const response = await axios.get(`${basicUrl}/getAllTestimonials`)
              return response.data 
        } catch (error) {
            console.log(error)
        }
})

export const getAgentsBasedOnLocation = createAsyncThunk('user/getAgentsBasedOnLocation',async({address})=>{
    console.log(address);
        
        try {
              const response = await axios.get(`${basicUrl}/get_agents_based_on_location/${address}`)
              return response.data 
        } catch (error) {
            console.log(error)
        }
})


const userSlice = createSlice({
   
    name:'user',
    initialState:{
        userList:null,
        load:false,
        error:null,
        isUserLoggedIn:false,
        toggleClicked:false,
        isFeedbackSending:false,
        testimonials:[],
        isTestimonialLoading:false,
        agentsList:[]
    },
     reducers:{
        setToggle:(state,action)=>{
            state.toggleClicked = action.payload
            console.log(action.payload)

        },
        logOutBuyer:(state,action)=>{
            state.user=[]
            state.isUserLoggedIn=action.payload
            localStorage.removeItem('userProfile')
            
        }
    },
    
    extraReducers:{

        [newBuyer.pending]:(state,action)=>{
            state.load=true
        },

        [newBuyer.fulfilled]:(state,action)=>{
            state.load=false
            state.userList=action.payload
            localStorage.setItem('userProfile',JSON.stringify({...action.payload}))
            state.isUserLoggedIn = true
        },

        [newBuyer.rejected]:(state,action)=>{
            state.load=false
            state.error= action.payload.error
        },

        [logIn.pending]:(state,action)=>{
            state.load=true
        },

        [logIn.fulfilled]:(state,action)=>{
            state.load=false
            state.userList=action.payload
            localStorage.setItem('userProfile',JSON.stringify({...action.payload}))
            state.isUserLoggedIn = true
        },

        [logIn.rejected]:(state,action)=>{
            state.load=false
            state.error= 'action.payload.error'
        },

        [getUser.pending]:(state,action)=>{
            state.load=true
        },

        [getUser.fulfilled]:(state,action)=>{
            state.load=false
            state.userList=action.payload
        },

        [getUser.rejected]:(state,action)=>{
            state.load=false
            state.error= action.payload.error
        },

        [sendFeedback.pending]:(state,action)=>{
            state.isFeedbackSending=true
        },

        [sendFeedback.fulfilled]:(state,action)=>{
            state.isFeedbackSending=false
        },

        [sendFeedback.rejected]:(state,action)=>{
            state.isFeedbackSending=true
        },

        [getAllTestimonials.pending]:(state,action)=>{
            state.isTestimonialLoading=true
        },
        [getAllTestimonials.fulfilled]:(state,action)=>{
            state.isTestimonialLoading=false
            state.testimonials=action.payload
        },
        [getAllTestimonials.rejected]:(state,action)=>{
            state.isTestimonialLoading=true
        },

        [getAgentsBasedOnLocation.pending]:(state,action)=>{
            state.isTestimonialLoading=true
        },
        [getAgentsBasedOnLocation.fulfilled]:(state,action)=>{
            state.isTestimonialLoading=false
            state.agentsList=action.payload
        },
        [getAgentsBasedOnLocation.rejected]:(state,action)=>{
            state.isTestimonialLoading=true
        },
    }
})
export const {setToggle,logOutBuyer} = userSlice.actions
export const userReducer = userSlice.reducer