import { FaFile, FaUser, FaWallet } from "react-icons/fa";
import { FaGear, FaMessage } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";

const styles={
    iconContainer:{
        marginLeft:'10px',
        width:'20px',
        height:'20px',
        color:'white'
    }
}

export const sideBar_list=[
    {
        title:'Dashboard',
        icon:<MdDashboard style={styles.iconContainer}/>
    },
    {
        title:'Account',
        icon:<FaUser style={styles.iconContainer}/>
    },
    {
        title:'Report',
        icon:<FaFile style={styles.iconContainer}/>
    },
     {
        title:'Notification',
        icon:<FaMessage style={styles.iconContainer}/>
    },
     {
        title:'Wallet',
        icon:<FaWallet style={styles.iconContainer}/>
    },
     {
        title:'Setting',
        icon:<FaGear style={styles.iconContainer}/>
    },
   
]