import React from 'react'
import '../styles/avatar.css'

const Avatar = ({image,size,isRound}) => {
  return (
    <div 
      className='avatar-container' 
      style={{
       width:`${size}px`, 
       height:`${size}px`,
       }}>
      <img 
       style={{borderRadius:isRound?'100%':'0px'}}
       src={image} 
       alt="avatar" 
       className='avatar-image'/>
    </div>
  )
}

export default Avatar