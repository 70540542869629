import React from 'react'
import '../styles/admin_card.css'

const Card = ({element,height,width}) => {
  return (
    <div 
      style={{height:height?height:'350px',width:width?width:'90%'}} 
      className='admin-card-container'>
      {element}
    </div>
  )
}

export default Card