import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { basicUrl } from "../../../../utils/api";


export const adminSignUp=createAsyncThunk('admin/adminSignUp',async({data,toast})=>{
    try {
        const response=await axios.post(`${basicUrl}/admin/admin_signUp`,data)
        toast.success('New Admin account created');  
        return response.data
    } catch (error) {
        toast.error(error.response.data.message);  
    }
})

export const adminSignIn=createAsyncThunk('admin/adminSignIn',async({data,navigate,toast})=>{
    try {
        const response=await axios.post(`${basicUrl}/admin/admin_login`,data)
        navigate('/admin')
        toast.success('Welcome back admin');  
        return response.data
    } catch (error) {
        toast.error(error.response.data.message);  
    }
})

export const getUserCount=createAsyncThunk('admin/getUserCount',async()=>{
    try {
        const response=await axios.get(`${basicUrl}/userCount`)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const getAgentCount=createAsyncThunk('admin/getAgentCount',async()=>{
    try {
        const response=await axios.get(`${basicUrl}/agentCount`)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const getAllAgentsInfo=createAsyncThunk('admin/getAllAgentsInfo',async()=>{
    try {
        const response=await axios.get(`${basicUrl}/get_all_agents_info`)
        return response.data
    } catch (error) {
        console.log(error)
    }
})


export const getAllUsersInfo=createAsyncThunk('admin/getAllUsersInfo',async()=>{
    try {
        const response=await axios.get(`${basicUrl}/allUser`)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const getAllFeedbacks=createAsyncThunk('admin/getAllFeedbacks',async()=>{
    try {
        const response=await axios.get(`${basicUrl}/getAllFeedbacks`)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const addTestimonial = createAsyncThunk('user/addTestimonial',async({testimonialData,toast})=>{
        
        try {
              const response = await axios.post(`${basicUrl}/newTestimonial`,testimonialData)
              toast.error('Testimonial successfully added');  
              return response.data 
        } catch (error) {
            toast.error(error.response.data.message);  
        }
})

export const updateAgentStatus = createAsyncThunk('user/updateAgentStatus',async({id,toast})=>{
        
        try {
              const response = await axios.put(`${basicUrl}/update_agent_status/${id}`)
             toast.success('agent status updated');  
              return response.data 
        } catch (error) {
            toast.error(error.response.data.message);  
        }
})

export const getAllReports=createAsyncThunk('admin/getAllReports',async()=>{
    try {
        const response=await axios.get(`${basicUrl}/get_all_reports`)
        return response.data
    } catch (error) {
        console.log(error)
    }
})

export const sendMessage=createAsyncThunk('admin/sendMessage',async({messageData,toast})=>{
    try {
        const response=await axios.post(`${basicUrl}/new_notification`,messageData)
        toast.success('message successfully send');  
        return response.data
    } catch (error) {
       toast.error(error.response.data.message);  
    }
})

export const deleteFeedback=createAsyncThunk('admin/deleteFeedback',async({id,toast})=>{
    try {
        await axios.delete(`${basicUrl}/delete_feedback/${id}`)
        toast.success('feedback successfully deleted');  
    } catch (error) {
       toast.error(error.response.data.message);  
    }
})




const adminSlice=createSlice({
    name:'admin',
    initialState:{
      isAdminLoggedIn:false,
      agentCount:0,
      userCount:0,
      loading:false,
      agentsInfo:[],
      usersInfo:[],
      feedbacks:[],
      isAdminSideBarVisible:false,
      isAgentStatusUpdating:false,
      reports:[],
      isReportingLoading:false,
      isModalVisible:false,
      modalData:{}
    },
    reducers:{
      setAdminSideBarVisible:(state,action)=>{
           state.isAdminSideBarVisible=!state.isAdminSideBarVisible
      },
      setModalElement:(state,action)=>{
           state.modalData=action.payload
      },
      setModalVisibility:(state,action)=>{
           state.isModalVisible=!state.isModalVisible
      }
    },
    extraReducers:{
       [adminSignIn.pending]:(state,action)=>{
           state.loading=true
           state.isAdminLoggedIn=false
        },
        [adminSignIn.fulfilled]:(state,action)=>{
          state.loading=false
          state.isAdminLoggedIn=true
        },
        [adminSignIn.rejected]:(state,action)=>{
          state.loading=true
          state.isAdminLoggedIn=false
        },
        [getUserCount.pending]:(state,action)=>{
           state.loading=true
        },
        [getUserCount.fulfilled]:(state,action)=>{
          state.loading=false
          state.userCount=action.payload
        },
        [getUserCount.rejected]:(state,action)=>{
          state.loading=true
        },
        [getAgentCount.pending]:(state,action)=>{
        state.loading=true
        },
        [getAgentCount.fulfilled]:(state,action)=>{
          state.loading=false
          state.agentCount=action.payload
        },
        [getAgentCount.rejected]:(state,action)=>{
          state.loading=true
        },
        [getAllAgentsInfo.pending]:(state,action)=>{
        state.loading=true
        },
        [getAllAgentsInfo.fulfilled]:(state,action)=>{
          state.loading=false
          state.agentsInfo=action.payload
        },
        [getAllAgentsInfo.rejected]:(state,action)=>{
          state.loading=true
        },
        [getAllUsersInfo.pending]:(state,action)=>{
        state.loading=true
        },
        [getAllUsersInfo.fulfilled]:(state,action)=>{
          state.loading=false
          state.usersInfo=action.payload
        },
        [getAllUsersInfo.rejected]:(state,action)=>{
          state.loading=true
        },

        [getAllFeedbacks.pending]:(state,action)=>{
        state.loading=true
        },
        [getAllFeedbacks.fulfilled]:(state,action)=>{
          state.loading=false
          state.feedbacks=action.payload
        },
        [getAllFeedbacks.rejected]:(state,action)=>{
          state.loading=true
        },

        [updateAgentStatus.pending]:(state,action)=>{
        state.isAgentStatusUpdating=true
        },
        [updateAgentStatus.fulfilled]:(state,action)=>{
          state.isAgentStatusUpdating=false
        },
        [updateAgentStatus.rejected]:(state,action)=>{
          state.isAgentStatusUpdating=true
        },

        [getAllReports.pending]:(state,action)=>{
          state.isReportingLoading=true
        },
        [getAllReports.fulfilled]:(state,action)=>{
          state.isReportingLoading=false
          state.reports=action.payload
        },
        [getAllReports.rejected]:(state,action)=>{
          state.isReportingLoading=false
        },

        

    }
})

export const {
setAdminSideBarVisible,
setModalElement,
setModalVisibility
}=adminSlice.actions
export const adminReducer=adminSlice.reducer