import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { MainRoute } from './routes/MainRoute';
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <div className="app">
      <ToastContainer/>
      <Router>
        <MainRoute/>
      </Router>
    </div>
  );
}

export default App;
