import React from 'react'
import '../styles/loading_page.css'
import LoadingAnimation from './LoadingAnimation'

const LoadingPage = () => {
  return (
    <div className='loading-page-container'>
      <LoadingAnimation/>
      <span className='loading-text'>Loading....</span>
    </div>
  )
}

export default LoadingPage