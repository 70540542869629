import React, { useState } from 'react'
import '../styles/upload_image.css'
import ImageUploading from 'react-images-uploading';
import {FaUpload} from 'react-icons/fa'
import { useDispatch} from 'react-redux';

const UploadImage = ({label,type,image,setImage}) => {
  const dispatch=useDispatch()
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    dispatch(setImage(imageList))
  };
  return (
    
     <ImageUploading
        multiple
        value={image}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
         {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
         <div className="upload__image-wrapper">
           {image?.length===0?<button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
             <FaUpload className='upload-icon'/>
             <span>{label?label:'Upload Image'}</span> 
            </button>:<button onClick={onImageRemoveAll}>Remove all images</button>}   
          </div>
        )}

      </ImageUploading>
 
  )
}

export default UploadImage