import React, { useEffect } from 'react'
import '../styles/notification.css'
import NotificationCard from '../components/NotificationCard'
import { useDispatch, useSelector } from 'react-redux'
import { getAgentMessage } from '../redux/agentSlice'


const Notification = () => {
  const {agentMessage,agentBasicInfo}=useSelector(state=>state.agent)
  const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(getAgentMessage({agentId:agentBasicInfo.agentId}))
  },[])

  console.log(agentMessage);

  return (
    <div className='notification-main-container'>
        { agentMessage.length>0?
          agentMessage?.map((data,index)=>
            <NotificationCard key={index} {...data}/>  
          ):"You don't have Message yet"
        }
        
        
       
    </div>
  )
}

export default Notification