import { createSlice } from "@reduxjs/toolkit";
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const cartSlice=createSlice({
    name:'stones',
    initialState:{
        cartStones:[],
        totalAmount:0,
        totalCost:0
    },
    reducers:{
        addToCart:(state,action)=>{
             const itemIndex=state.cartStones.findIndex(item=>item.id===action.payload.id)
             if(itemIndex>=0){
                state.cartStones[itemIndex].cartQuantity++
                toast.success(`
                    ${action.payload.title} amount increased to 
                    ${state.cartStones[itemIndex].cartQuantity}`)
             
             }else{
                  const temp={...action.payload,cartQuantity:1};
                  state.cartStones.push(temp);
                  toast.success(`${action.payload.title} added to the cart `)
             }
        },
        increaseCart:(state,action)=>{
            const itemIndex=state.cartStones.findIndex(item=>item.id===action.payload.id)
             if(itemIndex>=0){
                   state.cartStones[itemIndex].cartQuantity++
                   toast.success(`${action.payload.title} amount increased to ${state.cartStones[itemIndex].cartQuantity} `)
             }
            
        },

        decreaseCart:(state,action)=>{
            const itemIndex=state.cartStones.findIndex(item=>item.id===action.payload.id)
             if(state.cartStones[itemIndex].cartQuantity>1){
                   state.cartStones[itemIndex].cartQuantity--;
                   toast.error(`
                   ${action.payload.title} amount decreased to 
                   ${state.cartStones[itemIndex].cartQuantity}
                  
                   `)
             }else {
                return{
                    ...state,cartStones:state.cartStones.filter(item=>item.id!==action.payload.id)
                    
                }
             }
        },

        deleteItemFromCart:(state,action)=>{
             toast.error(`${action.payload.title} is deleted`,{position: "bottom-right",autoClose:1000,hideProgressBar: true})
             return{
                    ...state,cartStones:state.cartStones.filter(item=>item.id!==action.payload.id)
                }
        },

        clearCart:(state)=>{
            state.cartStones=[]
             toast.error(`Your cart is cleared`,{position: "bottom-right",autoClose:1000,hideProgressBar: true})
        }
        
    }
})
export const {addToCart,increaseCart,decreaseCart,deleteItemFromCart,clearCart} = cartSlice.actions 
export const cartReducer= cartSlice.reducer